import React from "react";
import AcquistaLayout from "../../components/acquista-layout";
import AcquistaThanks from "../../components/acquista-thanks";

const GrazieAcquistoBonificoPage = () => {
  return (
    <AcquistaLayout>
      <AcquistaThanks>
        <h1 className="fw-bold">Grazie per esserti iscritto all'Intranet Italia Day.</h1>
        <p>
          Riceverai una comunicazione all’indirizzo email che hai indicato nella sezione dei dati
          per la fatturazione con tutte le informazioni necessarie per effettuare il pagamento con
          bonifico.
        </p>
        <p>
          Una volta accreditato il bonifico riceverai il biglietto dell’evento di Milano e la
          relativa fattura.
        </p>
      </AcquistaThanks>
    </AcquistaLayout>
  );
};

export default GrazieAcquistoBonificoPage;
