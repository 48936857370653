import React from "react";
import Layout from "./layout";
import Seo from "./seo";
import BoxIniziativa from "./box-iniziativa";
import BoxSponsor from "./box-sponsor";
import BoxHelp from "./box-help";
import BoxNewsletter from "./box-newsletter";

const AcquistaLayout = ({ children }) => {
  return (
    <Layout>
      <Seo
        title={`Acquista`}
        description={`Compila il form per completare l'acquisto all'evento`}
      />
      <div className="box-ticket-wrap">
        <div className="box-ticket-wrap__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 text-center">
                <div className="box-ticket-wrap__subject text-uppercase">Acquista ticket</div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="box-ticket box-ticket--active">{children}</div>
            </div>
          </div>
        </div>
        <BoxIniziativa />
        <BoxSponsor />
        <BoxHelp />
        <BoxNewsletter />
      </div>
    </Layout>
  );
};

export default AcquistaLayout;
